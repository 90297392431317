/* @import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css); */
/* @import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR&display=swap'); */
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

/* .App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} */

body {
    margin: 0;
    overflow: hidden;
}
p,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}
* {
    box-sizing: border-box;
    font-family: 'Noto Sans CJK KR', 'Noto Sans KR', sans-serif;
}

.container {
    white-space: nowrap;
    margin: 0;
    padding: 0;
    /* display: table;             */
    word-spacing: 0;
    height: 100vh;
    /* overflow: hidden; */
    /* width: 100vw; */
}
.nav-container {
    /* padding: 15px; */
    /* display: table-cell; */
    /* white-space: normal; */
    /* padding-top: 30px; */
    display: inline-block;
    height: 100%;
    width: 260px;
    background-color: #80a7b8;
    /* padding-bottom: 100vh; */
    vertical-align: top;
}
.main-container {
    white-space: initial;
    height: 100vh;
    padding: 35px;
    /* vertical-align: top; */
    /* display: table-cell; */
    display: inline-block;
    min-width: 820px;
    width: calc(100vw - 260px);
    /* background-color: #faf5f2; */
    background-color: #fff;
    overflow: auto;
    position: static;
    right: 0;
}
.head-title {
    margin-bottom: 30px;
    font-size: 24pt;
}
.view-mode {
    display: inline-block;
    margin-right: 15px;
    padding-bottom: 8px;
    font-size: 14pt;
    border-bottom: 4px solid lightgrey;
}
.view-mode.active {
    font-weight: bolder;
    /* border-color: rgb(71, 32, 32); */
    border-color: #59363d;
}
.view-modes-container {
    margin-bottom: 20px;
}
.img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.diary-page .journal {
    width: 220px;
    display: inline-block;
    margin-right: 15px;
    /* margin-bottom: 15px;  */
    /* padding: 5px; */
    cursor: pointer;
    /* opacity: 0.6; */
}
.diary-page .journal:hover {
    /* opacity: 1; */
    transform: scale(1.02);
    transition: 0.1s ease-in-out;
}
.diary-page .journal:hover .journal__img {
    box-shadow: 5px 5px 8px #55555555;
    transition: 0.1s ease-in-out;
}
.diary-page .journal__img {
    padding-bottom: 120px;
    width: 100%;
    margin-bottom: 5px;
    border-radius: 4px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
}
.diary-page .journal__title {
    font-size: 14pt;
    margin-bottom: 4px;
    color: #111;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
}
.processing-screen {
    height: 100vh;
    width: 100vw;
    background-color: #80a7b888;
}
.journal__desc {
    font-size: 10pt;
    font-weight: 400;
    color: #666;
    margin: 0 0 10px 0;
    height: 40px;
    overflow: hidden;
}
.journals-group {
    margin-bottom: 50px;
}
.journals-group__title {
    font-size: 18pt;
    margin-bottom: 15px;
}

.profile {
    /* display: none; */
    background-color: #407186;
    padding: 25px;
    box-shadow: 0px 4px 6px #11111133;
}
.profile__img {
    display: inline-block;
    border-radius: 50%;
    width: 85px;
    height: 85px;
    background-color: #59363d;
}
.profile__text-area {
    padding-left: 30px;

    display: inline-block;
    vertical-align: top;
}
.profile__name {
    font-size: 13pt;
    color: #26151a;
    font-weight: bolder;
    margin-bottom: 4px;
}
.profile__number-of-journals {
    font-size: 11pt;
    color: #26151a;
    /* font-weight: 300; */
}
.profile__number-of-tags {
    font-size: 11pt;
    color: #26151a;
    /* font-weight: 300; */
}
.menu-nav-button {
    position: relative;
    /* margin: 5px; */
    height: 54px;
    line-height: 36px;
    /* vertical-align: middle; */
    font-size: 12pt;
    font-weight: 500;
    padding: 10px;
    color: #ffffffba;

    transition: ease-in 0.05s;
    /* border-bottom : 1px solid #59363D; */
}
.menu-nav-button:hover {
    color: #d6d6df;
}

a.active .menu-nav-button {
    /* border-radius: 25px; */
    background-color: #32546d;
    color: #ffffff;
    /* box-shadow: inset 0px 0 10px #222; */
}
a.active .menu-nav-button.expendable {
    /* border-radius: 25px; */
    background-color: #32546d;
    color: #ffffff;
    /* box-shadow: inset 0px 0 10px #222; */
}
.menu-nav-button__img-container {
    width: 45px;
    display: inline-block;
    text-align: center;
}

.menu-nav-button__text {
    /* width: 80px; */
    display: inline-block;
    text-align: center;
    /* width: 160px; */
}
.menu-nav-button .flipper-area {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    vertical-align: middle;
    text-align: center;
}
.menu-nav-button .flipper {
    /* line-height: 40px; */
    line-height: 3.4;
}
.menu-nav-button .flipper .flipper__click-area {
    position: absolute;
    right: 15px;
    line-height: 40px;
}

.menu-nav-button__children .menu-nav-button {
    /* background-color: #32546d8a !important; */
}
.menu-nav-button__children--diary-mode .menu-nav-button {
    background-color: #32546d8a !important;
}
a.active .menu-nav-button__children .menu-nav-button {
    background-color: #32546dcc !important;
}
.menu-nav-button__children .menu-nav-button {
    padding-left: 35px;
}

a {
    text-decoration: none;
}
#write-button {
    background-color: #f3485b;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    color: white;
    text-align: center;
    font-weight: bolder;
    line-height: 44px;
    font-size: 22pt;
    box-shadow: 4px 4px 6px #aaa;
    margin: 0 auto;
    position: fixed;
    bottom: 50px;
    left: calc(50vw + 150px);
}
.record-container {
    background-color: #000;
    position: static;
    height: 100%;
}
#record-video {
    width: 100vw;
    height: 100vh;
    z-index: 1;
    transform: scale(-1, 1);
    /* object-fit:contain; */
}

.record-edit-box {
    z-index: 5;
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: #111111aa;
    border: 1px solid #ffffffaa;
    /* box-sizing:content-box; */
    box-sizing: unset;
    /* box-sizing:content-box; */
    min-width: 300px;
    min-height: 500px;
    height: 500px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px #33333377;
}
.record-edit-box__move-bar {
    height: 30px;
    margin-top: -10px;
}
.record-edit-box__title {
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 20px;
    background-color: #eeeeff22;
    color: #eee;
}
.record-edit-box__desc {
    padding: 5px;
    border-radius: 5px;
    height: calc(100% - 140px);
    /* min-height: 400px; */
    margin-bottom: 15px;
    background-color: #eeeeff22;
    text-align: left;
    color: white;
    overflow: auto;
    padding: 15px;
}

.record-edit-box__title:focus {
    outline: none;
    background-color: #eeeeff33;
}
.record-edit-box__desc:focus {
    outline: none;
    background-color: #eeeeff33;
}
.text-tag {
    background-color: #ff00aaee;
}
.record-edit-box__button--regi {
    display: inline-block;
    padding: 5px;
    background-color: #32546d;
    border-radius: 5px;
    color: white;
    font-weight: 500;
}

.align-center {
    text-align: center;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}
.full-container {
    text-align: center;
    height: 100vh;
    width: 100vw;
}

.middle-container {
    display: table;
    position: relative;
    height: 100%;
    width: 100%;
    text-align: center;
}
.middle-content {
    display: table-cell;
    vertical-align: middle;
}

.no-drag {
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

/* in tutorials */

.header {
    font-weight: 400;
    /* font-weight: 600; */
    margin: 0;
    color: #111;
}
.header.default-size {
    font-size: 24pt;
}
.header.big-size {
    font-size: 36pt;
}
.paragraph {
    font-weight: 400;
    color: #222;
    white-space: break-spaces;
}
.paragraph.default-size {
    font-size: 14pt;
}
.paragraph.big-size {
    font-size: 18pt;
}
.paragraph.small-size {
    font-size: 10pt;
}

.span {
    font-weight: 400;
    color: #222;
}
.span.default-size {
    font-size: 14pt;
}
.span.big-size {
    font-size: 18pt;
}
.span.small-size {
    font-size: 10pt;
}

.grey-color,
.gray-color,
.grey1-color,
.gray1-color {
    color: #858585;
}

.grey2-color,
.gray2-color {
    color: #a9a9a9;
}
.grey3-color,
.gray3-color {
    color: #bdbdbd;
}

.button {
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
    transition: ease-in 0.25s;
}
.button.big-size {
    padding: 12pt;
    font-size: 22pt;
    line-height: 22pt;
}
.button.default-size {
    padding: 8pt;
    font-size: 12pt;
    font-weight: 500;
    line-height: 12pt;
}

.button.small-size {
    padding: 6pt;
    font-size: 12pt;
    font-weight: 500;
    line-height: 12pt;
}

.button.functional-color {
    /* background-color: #32546d; */
    background-color: #f3485b;
    color: white;
}
.button.primary-color {
    background-color: #32546d;
    /* background-color: #f3485b; */
    color: white;
}

.button.secondary-color {
    /* background-color: #32546daa; */
    background-color: #444444;
    color: white;
}
.button.disabled {
    color: white;
    background-color: #aaa;
    cursor: not-allowed;
}
.button.default-width {
    min-width: 100px;
}
.button.long-fixed-width {
    width: 1000px;
}
.button.short-width {
    min-width: 200px;
}
.button.middle-width {
    min-width: 300px;
}

.inputbox {
    outline: 2px solid #ccd;
    border: none;

    /* line-height: 38pt; */
}
.inputbox:focus {
    /* border-radius: 0; */
    outline: 2px ridge #336;
}

.inputbox.default-size {
    font-size: 12pt;
    padding: 6px;
}
.inputbox.big-size {
    font-size: 32pt;
    padding: 16px;
}
.inputbox.deault-width {
    width: 600px;
}

.full-width {
    width: 100%;
}
.primary-color {
    color: #32546d;
}
.functional-color {
    color: #f3485b;
}
.alert-color {
    color: #ef382b;
}
.secondary-color {
    color: #444444;
}
.selectbox {
    border: 2px solid #333;
    width: 1000px;
    display: inline-block;
    max-height: 500px;
    overflow: auto;
}
.selectbox .selectbox__item {
    min-height: 52px;
    line-height: 52px;
    font-size: 20pt;
    cursor: pointer;
}
.selectbox .selectbox__item.selected {
    color: white;
    background-color: #444;
}
.selectbox .selectbox__item:not(:last-child) {
    border-bottom: 1px solid #333;
}
.moveable-direction.moveable-e,
.moveable-direction.moveable-w {
    cursor: ew-resize;
    opacity: 0;
}
.moveable-direction.moveable-s,
.moveable-direction.moveable-n {
    cursor: ns-resize;
    opacity: 0;
}
.moveable-direction.moveable-nw,
.moveable-direction.moveable-se,
.moveable-reverse .moveable-direction.moveable-ne,
.moveable-reverse .moveable-direction.moveable-sw {
    cursor: nwse-resize;
    opacity: 0;
}
.moveable-direction.moveable-ne,
.moveable-direction.moveable-sw,
.rCSckyn7i.moveable-reverse .moveable-direction.moveable-nw,
moveable-reverse .moveable-direction.moveable-se {
    cursor: nesw-resize;
    opacity: 0;
}
.moveable-line {
    opacity: 0;
}
.moveable[data-able-draggable='true'] + .record-container .record-edit-box {
    /* opacity: 1; */
    box-shadow: 0 0 20px #222;
    cursor: move;
    background-color: #000000aa;
    /* border: 3px solid red; */
    /* background-color: #222; */
    /* border: 3px solid red; */

    /* padding: 1px; */
}

.btn {
    cursor: pointer;
}

.journal-page .journal__title {
    font-size: 14pt;
    min-height: 14pt;
}

.journal-page .moveable-line.moveable-direction.moveable-n,
.journal-page .moveable-line.moveable-direction.moveable-e,
.journal-page .moveable-line.moveable-direction.moveable-w {
    display: none;
}
.journal-page .paragraph-container {
    overflow: auto;
    /* height: 500px; */
}
.diaries-page .diary-item {
    display: inline-block;
    margin-right: 28px;
    transition: 0.125s ease-in-out;
}
.diaries-page .diary-item:hover {
    transform: scale(1.03);
}
.diaries-page .diary-item:hover .diary-item__image {
    box-shadow: 5px 5px 8px #55555555;
}
.diaries-page .diary-item__image {
    font-size: 55pt;
    line-height: 140px;
    /* vertical-align: middle; */
    height: 150px;
    width: 150px;
    background-color: #80a7b8;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 10px;
}

.diaries-page .diary-item__title {
    color: #333;
    font-size: 14pt;
    text-align: center;
}

.home-page .celebrate-message {
    font-weight: bolder;
    font-size: 14pt;
    margin-bottom: 45px;
}

.home-page .recent-issues__title {
    font-size: 18pt;
    font-weight: bold;
}
.home-page .recent-issues {
    display: inline-block;
    width: 420px;
    margin-right: 80px;
}
.home-page .recent-issue li {
    text-overflow: ellipsis;
    /* overflow: hidden; */
    white-space: nowrap;
}
.home-page .advertisement {
    display: inline-block;
    min-width: 420px;
}

a {
    color: #333;
}

.journal-page .journal__title {
    background-color: #eafaffee;
    border-radius: 5px;
    padding: 4px 4px 4px 8px;
    box-shadow: 2px 2px 4px #ddd;
}
.journal-page .journal__description {
    background-color: #eafaffbb;
    border-radius: 5px;
    padding: 4px 4px 4px 8px;
    box-shadow: 2px 2px 4px #ddd;
    margin-bottom: 20px;
}
